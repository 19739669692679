import React from "react";

interface ThumbsUpIconProps {
	active?: boolean;
}
const ThumbsUpIcon: React.FC<ThumbsUpIconProps> = ({ active }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.1999 6.72007C14.0126 6.49517 13.7782 6.31413 13.5132 6.18972C13.2483 6.06531 12.9593 6.00057 12.6666 6.00007H9.62658L9.99992 5.04674C10.1552 4.62933 10.2069 4.18047 10.1506 3.73868C10.0943 3.29689 9.93171 2.87535 9.67671 2.51022C9.42171 2.14509 9.08194 1.84727 8.68655 1.6423C8.29115 1.43733 7.85194 1.33133 7.40658 1.3334C7.27834 1.33367 7.1529 1.37092 7.0453 1.44069C6.9377 1.51045 6.8525 1.60977 6.79992 1.72674L4.89992 6.00007H3.33325C2.80282 6.00007 2.29411 6.21078 1.91904 6.58586C1.54397 6.96093 1.33325 7.46964 1.33325 8.00007V12.6667C1.33325 13.1972 1.54397 13.7059 1.91904 14.081C2.29411 14.456 2.80282 14.6667 3.33325 14.6667H11.8199C12.2878 14.6666 12.7408 14.5024 13.1001 14.2028C13.4595 13.9031 13.7024 13.487 13.7866 13.0267L14.6333 8.36007C14.6856 8.07166 14.6739 7.77526 14.5991 7.49185C14.5242 7.20845 14.3879 6.94497 14.1999 6.72007ZM4.66658 13.3334H3.33325C3.15644 13.3334 2.98687 13.2632 2.86185 13.1381C2.73682 13.0131 2.66659 12.8435 2.66659 12.6667V8.00007C2.66659 7.82326 2.73682 7.65369 2.86185 7.52867C2.98687 7.40364 3.15644 7.3334 3.33325 7.3334H4.66658V13.3334ZM13.3333 8.12007L12.4866 12.7867C12.4582 12.942 12.3756 13.0822 12.2535 13.1823C12.1314 13.2824 11.9778 13.336 11.8199 13.3334H5.99992V6.80674L7.81325 2.72674C7.99991 2.78115 8.17326 2.87367 8.32236 2.99846C8.47146 3.12324 8.59307 3.27758 8.67952 3.45173C8.76597 3.62588 8.81537 3.81606 8.82462 4.01027C8.83387 4.20448 8.80276 4.39849 8.73325 4.58007L8.37992 5.5334C8.30463 5.73491 8.27921 5.95164 8.30583 6.16509C8.33246 6.37855 8.41033 6.58239 8.53281 6.75923C8.65528 6.93607 8.81873 7.08065 9.00919 7.18063C9.19965 7.28061 9.41148 7.33303 9.62658 7.3334H12.6666C12.7645 7.33324 12.8613 7.35467 12.95 7.39615C13.0387 7.43763 13.1172 7.49815 13.1799 7.5734C13.2441 7.64762 13.2912 7.7351 13.3177 7.8296C13.3442 7.9241 13.3495 8.02328 13.3333 8.12007Z"
				fill={active ? "#1673ff" : "#7A7C80"}
				style={{ fill: active ? "#1673ff" : "#7A7C80", fillOpacity: 1 }}
			/>
		</svg>
	);
};

export default ThumbsUpIcon;
