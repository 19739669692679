import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NewSearchBar from "./NewSearchBar";
import PopularSearches from "./PopularSearches";
import Header from "../../layout/Header";
import {
	CircularProgress,
	circularProgressClasses,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import {
	EnhancedTagResponse,
	ISingleDataset,
} from "../../redux/models/datasetModel";
import useDataset from "../../hooks/useDataset";
import {
	// POPULAR_QUESTIONS,
	// MARKETING_QUESTIONS,
	FundRaisingHomeQuestions,
	MarketingHomeQuestions,
} from "../../utils/constants";
import useHistory from "../../hooks/useHistory";
import useAuth from "../../hooks/useAuth";
import CustomLoader from "../result-item/CustomLoader";
import ResultItems from "./ResultItems";
import TopHeading from "../../pages/home/TopHeading";
import NewHomeSearchBar from "./NewHomeSearchBar";
import PopularSearchesHome from "./PopularSearchesHome";
import { theme } from "../../theme";
import InfoResult from "./InfoResult";
import RecommendedContent from "./RecommendedContentProps";

import Switch from "@mui/material/Switch";
// import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { TagResponse } from "../../openapi";

// const icon = (
// 	<Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
// 		<svg>
// 			<Box
// 				component="polygon"
// 				points="0,100 50,00, 100,100"
// 				sx={(theme) => ({
// 					fill: theme.palette.common.white,
// 					stroke: theme.palette.divider,
// 					strokeWidth: 1,
// 				})}
// 			/>
// 		</svg>
// 	</Paper>
// );

// export function SimpleFade() {
// 	const [checked, setChecked] = React.useState(false);

// 	const handleChange = () => {
// 		setChecked((prev) => !prev);
// 	};

// 	return (
// 		<Box sx={{ height: 180 }}>
// 			<FormControlLabel
// 				control={<Switch checked={checked} onChange={handleChange} />}
// 				label="Show"
// 			/>
// 			<Box sx={{ display: "flex" }}>
// 				<Fade in={checked}>{icon}</Fade>
// 			</Box>
// 		</Box>
// 	);
// }
interface SearchResultsProps {
	totalResults: number;
}

// interface SearchBarProps {
// 	value?: string;
// 	width?: string;
// 	onChange?: (value: string) => void;
// 	placeholder?: string;
// 	height?: string;
// 	onCancelResearch?: (value: string) => void;
// 	onSearch?: (value?: string) => void;
// 	className?: string;
// 	style?: React.CSSProperties;
// 	disabled?: boolean;
// 	options?: string[];
// }
const SearchResults: React.FC<SearchResultsProps> = ({ totalResults }) => {
	const {
		datasets,
		exaSearching,
		isExaDataset,
		isLoading,
		setRandomQuestions,
		randomQuestions,
		getExaDataset,
		getDataset,
		getThreadId,
		// getRandomThreeQuestions,
		setShowPopularQusetion,
		setMaxSearchLimit,
		questionReplaceHandler,
		clearDataset,
		setShowNoResultText,
		queryType,
		setQuryType,
		getCategories,
		allCategoriesData,
	} = useDataset();
	const { searchNumberThisMonth } = useHistory();
	const { userInfo } = useAuth();
	const [showResultInfo, setShowResultInfo] = useState<boolean>(true);
	const [question, setQuestion] = useState<string>("");
	const [questionManupulated, setQuestionManupulated] =
		useState<boolean>(false);
	const [categories, setCategories] = useState<
		EnhancedTagResponse[] | undefined
	>(undefined);
	// const [checked, setChecked] = React.useState(true);
	const [checked, setChecked] = useState<boolean>(() => {
		// Load the initial value from localStorage
		const storedValue = localStorage.getItem("checked");
		return storedValue ? JSON.parse(storedValue) : true;
	});
	const [homeQuestions, setHomeQuestions] = useState<string[]>([]);
	// Update localStorage whenever `checked` changes
	useEffect(() => {
		localStorage.setItem("checked", JSON.stringify(checked));
	}, [checked]);
	useEffect(
		() => {
			const getAllCategories = async () => {
				const allCategories = await getCategories();
				setCategories(allCategories);
			};
			getAllCategories();
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const handleCheckedChange = () => {
		setChecked((prev) => !prev);
	};
	const handleChangeQueryType = (event: SelectChangeEvent) => {
		const categoryType = event.target.value;
		setPoplarQuestions(categoryType);
	};
	const setPoplarQuestions = async (currentQueryType?: string) => {
		try {
			const catrgories = allCategoriesData
				? allCategoriesData
				: await getCategories();
			if (!currentQueryType && catrgories && catrgories.length > 0) {
				currentQueryType = catrgories[0].vectorClass;
			}
			if (currentQueryType) {
				setQuryType(currentQueryType);
			}
			const newCurrentCategory = catrgories?.find(
				(category) => category.vectorClass === currentQueryType
			);
			// console.log("newCurrentCategory: ", newCurrentCategory);
			if (
				newCurrentCategory &&
				newCurrentCategory?.additionalInfoSimilar
			) {
				const qs = newCurrentCategory?.additionalInfoSimilar.map(
					(item: any) => item.similarquestion as string
				);
				if (qs) {
					// console.log("additionalInfoSimilar questions", qs);
					setRandomQuestions(getRandomQuestions(qs));
				} else {
					// console.log("no additionalInfoSimilar questions");
					setRandomQuestions([]);
				}
			} else {
				// console.log("no similar questions");
				setRandomQuestions([]);
			}
			if (newCurrentCategory && newCurrentCategory?.additionalInfoHome) {
				const qs = newCurrentCategory?.additionalInfoHome.map(
					(item: any) => item.homequestion as string
				);
				if (qs) {
					// console.log("additionalInfoHome questions", qs);
					setHomeQuestions(qs);
				} else {
					// console.log("no additionalInfoHome questions");
					setHomeQuestions([]);
				}
			} else {
				// console.log("no home questions");
				setHomeQuestions([]);
			}
		} catch (error) {
			console.log("error: ", error);
		}
	};
	const getRandomQuestions = (qsource?: string[]) => {
		const questionSource: string[] = qsource ? qsource : randomQuestions;
		const selectedQuestions: string[] = [];
		const limit =
			questionSource && questionSource.length > 3
				? 3
				: questionSource.length;
		while (selectedQuestions.length < limit) {
			const randomIndex = Math.floor(
				Math.random() * questionSource.length
			);
			const randomQuestion = questionSource[randomIndex];
			if (!selectedQuestions.includes(randomQuestion)) {
				selectedQuestions.push(randomQuestion);
			}
		}
		return selectedQuestions;
	};
	const handleChange = (value: string) => {
		// console.log("handleChange for: ", value);
		const inputText = value;
		setQuestion(inputText ?? "");
		if (datasets && datasets.length > 0) {
			setQuestionManupulated(true);
		}
	};
	const handleQuestionClick = (query: string) => {
		// console.log("handleQuestionClick for: ", query);
		questionReplaceHandler(true, query);
		if (
			(searchNumberThisMonth > 10 &&
				userInfo?.subscriptionType === "Free") ||
			(searchNumberThisMonth > 25 &&
				userInfo?.subscriptionType === "Starter")
		) {
			setShowPopularQusetion(false);
			setMaxSearchLimit(true);
			return;
		} else {
			setMaxSearchLimit(false);
			setPoplarQuestions(queryType);
			setShowPopularQusetion(true);
			clearDataset();
			getThreadId();
			getDataset(query);
			setQuestion(query);
		}
	};
	const handleSubmit = () => {
		// e.preventDefault();
		// console.log("searching for: ", question);
		if (!question) return;

		try {
			setQuestionManupulated(false);
			if (
				(searchNumberThisMonth > 10 &&
					userInfo?.subscriptionType === "Free") ||
				(searchNumberThisMonth > 25 &&
					userInfo?.subscriptionType === "Starter")
			) {
				setShowPopularQusetion(false);
				setMaxSearchLimit(true);
				return;
			} else {
				setMaxSearchLimit(false);
				setRandomQuestions(getRandomQuestions());
				setShowPopularQusetion(true);
				clearDataset();
				getThreadId();
				getDataset(question);
				setQuestion(question);
			}
		} catch (error: any) {
			console.log("error: ", error);
		}
	};
	const onCancelResearch = (value: string) => {
		// console.log("cancelling research for ", value);
		setQuestionManupulated(true);
		setQuestion("");
	};

	useEffect(() => {
		// if (showNoResultText) {
		// 	console.log("showNoResultText", showNoResultText);
		// 	setShowNoResultText(true);
		// }
		if (datasets && datasets[0]?.result?.length < 1) {
			if (!isExaDataset && !isLoading) {
				// console.log("getExaDataset");
				getExaDataset(question);
				setShowNoResultText(true);
			}
			if (!isExaDataset) {
				// setExaSearching(false);
			}
		}
	}, [
		getExaDataset,
		isExaDataset,
		isLoading,
		question,
		datasets,
		setShowNoResultText,
	]);
	useEffect(() => {
		setPoplarQuestions(queryType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Container>
			<Header />
			{datasets || datasets?.length > 0 ? (
				<>
					<Box
						sx={{
							border: "1px solid #D3D9E3",
						}}
					>
						<SearchFormWrapper>
							{categories && (
								<FormControlWrapper size="small" fullWidth>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={queryType}
										onChange={handleChangeQueryType}
										sx={{
											height: "40px",
											fontSize: "14px",
											fontWeight: 500,
											color: "#323339",
											// border: "1px solid #C3C6D6",
										}}
										style={{ border: "none" }}
									>
										{/* <MenuItem value={IQueryType.FUNDRAISINNG}>
										Fundraising
									</MenuItem>
									<MenuItem value={IQueryType.MARKETING}>
										Growth
									</MenuItem> */}

										{categories.map((category, index) => (
											<MenuItem
												key={index}
												value={category.vectorClass}
											>
												{category.title}
											</MenuItem>
										))}
									</Select>
								</FormControlWrapper>
							)}
							<NewSearchBar
								question={
									question
										? question
										: question.length === 0 &&
										  questionManupulated
										? ""
										: datasets && datasets.length > 0
										? datasets[0]?.question
										: ""
								}
								setQuestion={setQuestion}
								onSearch={handleSubmit}
								onCancelResearch={onCancelResearch}
								onChange={handleChange}
							/>
						</SearchFormWrapper>
					</Box>
					<BodyWrapper>
						<ContentWrapper>
							<ResultsColumn>
								{datasets &&
									datasets.length > 0 &&
									datasets[0]?.result && (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												// marginBottom: "24px",
												gap: "16px",
											}}
										>
											<Box
												sx={{
													display: "flex",
													justifyContent:
														"space-between",
													alignItems: "center",
												}}
											>
												<ResultCount
												// sx={{ marginBottom: "16px" }}
												>
													Showing{" "}
													{datasets &&
														datasets[0]?.result
															?.length}{" "}
													results{" "}
													{!isExaDataset
														? "from our curated library"
														: "from the world wide web"}
												</ResultCount>
												{!checked && (
													<FormControlLabel
														control={
															<Switch
																checked={
																	checked
																}
																onChange={
																	handleCheckedChange
																}
															/>
														}
														label="Show recommendation"
														labelPlacement="start"
														slotProps={{
															typography: {
																fontFamily:
																	"Inter",
																fontStyle:
																	"normal",
																fontWeight: 500,
																fontSize:
																	"14px",
																lineHeight:
																	"17px",
																color: "#515151",
															},
														}}
														sx={{
															fontFamily: "Inter",
															fontStyle: "normal",
															fontWeight: 500,
															fontSize: "14px",
															lineHeight: "17px",
															color: "#515151",
														}}
													/>
												)}
											</Box>
											{showResultInfo && (
												<InfoResult
													title={
														!isExaDataset
															? "How we curated these results"
															: "Showing results from outside our library"
													}
													description={
														!isExaDataset
															? "We curate high-quality resources using machine learning and human review. Our curation prioritizes links that are detailed, tactical, and credible. We feature real examples, personal stories, and insights from trusted founders, operators, and VCs to help founders quickly access valuable information without the noise."
															: "Oops! We don't have any results in our curated library that match your search. Below you'll find some results from outside our library, pulled directly from the web. These results haven't been curated and quality can be mixed. If this isn't what you're looking for, try rephrasing your search."
													}
													setShowResultInfo={
														setShowResultInfo
													}
												/>
											)}
											{checked && (
												<RecommendedContent
													setShow={
														handleCheckedChange
													}
													question={
														question
															? question
															: question.length ===
																	0 &&
															  questionManupulated
															? ""
															: datasets &&
															  datasets.length >
																	0
															? datasets[0]
																	?.question
															: ""
													}
												/>
											)}
										</Box>
									)}
								<ResultItemWrapper>
									{datasets &&
										datasets?.length > 0 &&
										datasets.map(
											(
												element: ISingleDataset,
												index: number
											) =>
												element.loading || isLoading ? (
													<Box
														key={
															Math.random() +
															index
														}
														width={"100%"}
														gap={"16px"}
													>
														<Typography
															sx={{
																padding:
																	"8px 16px",
																gap: "10px",
																background:
																	"#FFFFFF",
																fontFamily:
																	"Inter",
																fontStyle:
																	"normal",
																fontWeight: 500,
																fontSize:
																	"14px",
																lineHeight:
																	"17px",
																color: "#7A7C80",
																textAlign:
																	"left",
															}}
														>
															<CircularProgress
																variant="indeterminate"
																disableShrink
																sx={{
																	color: (
																		theme: any
																	) =>
																		theme
																			.palette
																			.mode ===
																		"light"
																			? "#1a90ff"
																			: "#308fe8",
																	animationDuration:
																		"550ms",
																	[`& .${circularProgressClasses.circle}`]:
																		{
																			strokeLinecap:
																				"round",
																		},
																	// paddingRight: "10px",
																}}
																size={20}
																thickness={4}
															/>
															{exaSearching ? (
																<span
																	style={{
																		marginLeft:
																			"10px",
																	}}
																>
																	Finding more
																	results
																	outside our
																	curated
																	library!
																	Searching
																	the world
																	wide web.
																</span>
															) : (
																<span
																	style={{
																		marginLeft:
																			"10px",
																	}}
																>
																	Searching
																	our curated
																	library
																</span>
															)}
														</Typography>
														<Box
															width={"100%"}
															bgcolor={"#fff"}
															p={"24px"}
															borderRadius={
																"20px"
															}
														>
															<CustomLoader />
														</Box>
													</Box>
												) : (
													<ResultItems
														key={`${element.historyId}-${index}`}
														items={element.result}
													/>
												)
										)}
								</ResultItemWrapper>
							</ResultsColumn>
							<PopularSearches onSearch={handleQuestionClick} />
						</ContentWrapper>
					</BodyWrapper>
				</>
			) : (
				<ContentWrapperHome>
					<TopHeading />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "start",
							gap: "16px",
						}}
					>
						{categories && (
							<FormControl
								sx={{
									width: "194px",
									border: "none",
								}}
								size="small"
								fullWidth
							>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={queryType}
									onChange={handleChangeQueryType}
									sx={{
										height: "40px",
										fontSize: "14px",
										fontWeight: 600,
										color: "#323339",
									}}
									style={{ border: "none" }}
								>
									{categories.map((category, index) => (
										<MenuItem
											key={index}
											value={category.vectorClass}
										>
											{category.title} Database
										</MenuItem>
									))}
									{/* <MenuItem value={IQueryType.FUNDRAISINNG}>
									Fundraising Database
								</MenuItem>
								<MenuItem value={IQueryType.MARKETING}>
									Growth Database
								</MenuItem> */}
								</Select>
							</FormControl>
						)}
						<NewHomeSearchBar
							question={
								question
									? question
									: question.length === 0 &&
									  questionManupulated
									? ""
									: datasets && datasets.length > 0
									? datasets[0]?.question
									: ""
							}
							setQuestion={setQuestion}
							onSearch={handleSubmit}
							onCancelResearch={onCancelResearch}
							onChange={handleChange}
						/>
						<PopularSearchesHome
							onSearch={handleQuestionClick}
							randomQuestions={
								// queryType === "Chunked_Data_v16"
								// 	? FundRaisingHomeQuestions
								// 	: queryType === "Marketing_Data_v2"
								// 	? MarketingHomeQuestions
								// 	: homeQuestions && homeQuestions.length > 0
								// 	? homeQuestions
								// 	: []
								homeQuestions && homeQuestions.length > 0
									? homeQuestions
									: queryType === "Chunked_Data_v16"
									? FundRaisingHomeQuestions
									: queryType === "Marketing_Data_v2"
									? MarketingHomeQuestions
									: []
							}
						/>
					</Box>
				</ContentWrapperHome>
			)}
		</Container>
	);
};
const FormControlWrapper = styled(FormControl)(({ theme }) => ({
	width: "130px",
	border: "none",
	[theme.breakpoints.down("lg")]: {
		width: "37%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "37%",
	},
	"@media (max-width: 400px)": {
		width: "41%",
	},
}));
const SearchFormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "start",
	gap: "8px",
	height: "40px",
	marginLeft: "124px",
	marginTop: "24px",
	marginBottom: "24px",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "row",
		alignItems: "flex-start",
		height: "40px",
		margin: "16px 32px",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		height: "90px",
		margin: "16px 32px",
	},
}));
const BodyWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "100%",
	padding: "0 124px",
	[theme.breakpoints.down("lg")]: {
		padding: "0 32px",
	},
	[theme.breakpoints.down("sm")]: {
		padding: "0 32px",
	},
}));
const ResultItemWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	gap: "40px",
	[theme.breakpoints.down("lg")]: {
		gap: "24px",
	},
	[theme.breakpoints.down("sm")]: {
		gap: "24px",
	},
}));
const Container = styled(Box)(() => ({
	backgroundColor: "#fff",
	display: "flex",
	padding: 0,
	margin: 0,
	// paddingTop: 16,
	// paddingLeft: 124,
	flexDirection: "column",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
	alignSelf: "start",
	marginTop: "24px",
	marginBottom: "54px",
	width: "100%",
	// maxWidth: 1114,
	display: "flex",
	gap: "124px",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "column-reverse",
		gap: "24px",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column-reverse",
		gap: "24px",
		maxWidth: "100%",
	},
}));

const ResultsColumn = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	lineHeight: "normal",
	width: "100%",
	maxWidth: "618px",
	marginLeft: 0,
	[theme.breakpoints.down("lg")]: {
		width: "100%",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
		maxWidth: "100%",
	},
}));

const ResultCount = styled(Typography)(({ theme }) => ({
	color: "#7a7c80",
	fontWeight: 500,
	fontSize: "14px",
	// marginBottom: "48px",
	fontFamily: "Inter",
	[theme.breakpoints.down("lg")]: {
		maxWidth: "100%",
		marginBottom: "24px",
	},
	[theme.breakpoints.down("sm")]: {
		maxWidth: "100%",
		marginBottom: "24px",
	},
}));

// const ContainerHome = styled(Box)(() => ({
// 	backgroundColor: "#fff",
// 	display: "flex",
// 	padding: 0,
// 	margin: 0,
// 	flexDirection: "column",
// }));

const ContentWrapperHome = styled(Box)(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	borderTop: "1px solid #D3D9E3",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "column",
		maxWidth: "100%",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		maxWidth: "100%",
	},
}));

export default SearchResults;
