import React from "react";

const InfoRound = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99992 1.33337C6.68138 1.33337 5.39245 1.72437 4.29612 2.45691C3.19979 3.18945 2.34531 4.23064 1.84072 5.44882C1.33614 6.66699 1.20412 8.00744 1.46135 9.30064C1.71859 10.5938 2.35353 11.7817 3.28588 12.7141C4.21823 13.6464 5.40611 14.2814 6.69932 14.5386C7.99253 14.7958 9.33297 14.6638 10.5511 14.1592C11.7693 13.6547 12.8105 12.8002 13.5431 11.7038C14.2756 10.6075 14.6666 9.31858 14.6666 8.00004C14.6646 6.23253 13.9616 4.53798 12.7118 3.28817C11.462 2.03835 9.76743 1.33534 7.99992 1.33337ZM7.99992 13.3334C6.94509 13.3334 5.91394 13.0206 5.03688 12.4345C4.15982 11.8485 3.47623 11.0156 3.07256 10.041C2.6689 9.06648 2.56328 7.99412 2.76907 6.95956C2.97485 5.92499 3.48281 4.97468 4.22869 4.2288C4.97457 3.48292 5.92487 2.97497 6.95944 2.76919C7.99401 2.5634 9.06636 2.66902 10.0409 3.07268C11.0154 3.47635 11.8484 4.15994 12.4344 5.037C13.0205 5.91406 13.3333 6.94521 13.3333 8.00004C13.3316 9.41403 12.7692 10.7696 11.7694 11.7695C10.7695 12.7693 9.41391 13.3318 7.99992 13.3334ZM7.99992 7.66671C7.82311 7.66671 7.65354 7.73695 7.52852 7.86197C7.40349 7.98699 7.33326 8.15656 7.33326 8.33337V10.3334C7.33326 10.5102 7.40349 10.6798 7.52852 10.8048C7.65354 10.9298 7.82311 11 7.99992 11C8.17673 11 8.3463 10.9298 8.47133 10.8048C8.59635 10.6798 8.66659 10.5102 8.66659 10.3334V8.33337C8.66659 8.15656 8.59635 7.98699 8.47133 7.86197C8.3463 7.73695 8.17673 7.66671 7.99992 7.66671ZM7.99992 5.00004C7.8351 5.00004 7.67399 5.04891 7.53695 5.14048C7.39991 5.23205 7.2931 5.3622 7.23002 5.51447C7.16695 5.66674 7.15045 5.8343 7.1826 5.99595C7.21476 6.1576 7.29412 6.30609 7.41067 6.42263C7.52721 6.53917 7.6757 6.61854 7.83735 6.65069C7.999 6.68285 8.16655 6.66635 8.31882 6.60327C8.4711 6.5402 8.60125 6.43339 8.69281 6.29635C8.78438 6.15931 8.83326 5.99819 8.83326 5.83337C8.83326 5.61236 8.74546 5.4004 8.58918 5.24412C8.4329 5.08784 8.22094 5.00004 7.99992 5.00004Z"
				fill="#2A4B90"
				style={{ fill: "#2A4B90", fillOpacity: 1 }}
			/>
		</svg>

		// <svg
		// 	width="16"
		// 	height="16"
		// 	viewBox="0 0 16 16"
		// 	fill="none"
		// 	xmlns="http://www.w3.org/2000/svg"
		// >
		// 	<path
		// 		d="M7.99992 1.33337C6.68138 1.33337 5.39245 1.72437 4.29612 2.45691C3.19979 3.18945 2.34531 4.23064 1.84072 5.44882C1.33614 6.66699 1.20412 8.00744 1.46135 9.30064C1.71859 10.5938 2.35353 11.7817 3.28588 12.7141C4.21823 13.6464 5.40611 14.2814 6.69932 14.5386C7.99253 14.7958 9.33297 14.6638 10.5511 14.1592C11.7693 13.6547 12.8105 12.8002 13.5431 11.7038C14.2756 10.6075 14.6666 9.31858 14.6666 8.00004C14.6646 6.23253 13.9616 4.53798 12.7118 3.28817C11.462 2.03835 9.76743 1.33534 7.99992 1.33337ZM7.99992 13.3334C6.94509 13.3334 5.91394 13.0206 5.03688 12.4345C4.15982 11.8485 3.47623 11.0156 3.07256 10.041C2.6689 9.06648 2.56328 7.99412 2.76907 6.95956C2.97485 5.92499 3.48281 4.97468 4.22869 4.2288C4.97457 3.48292 5.92487 2.97497 6.95944 2.76919C7.99401 2.5634 9.06636 2.66902 10.0409 3.07268C11.0154 3.47635 11.8484 4.15994 12.4344 5.037C13.0205 5.91406 13.3333 6.94521 13.3333 8.00004C13.3316 9.41403 12.7692 10.7696 11.7694 11.7695C10.7695 12.7693 9.41391 13.3318 7.99992 13.3334ZM7.99992 7.66671C7.82311 7.66671 7.65354 7.73695 7.52852 7.86197C7.40349 7.98699 7.33326 8.15656 7.33326 8.33337V10.3334C7.33326 10.5102 7.40349 10.6798 7.52852 10.8048C7.65354 10.9298 7.82311 11 7.99992 11C8.17673 11 8.3463 10.9298 8.47133 10.8048C8.59635 10.6798 8.66659 10.5102 8.66659 10.3334V8.33337C8.66659 8.15656 8.59635 7.98699 8.47133 7.86197C8.3463 7.73695 8.17673 7.66671 7.99992 7.66671ZM7.99992 5.00004C7.8351 5.00004 7.67399 5.04891 7.53695 5.14048C7.39991 5.23205 7.2931 5.3622 7.23002 5.51447C7.16695 5.66674 7.15045 5.8343 7.1826 5.99595C7.21476 6.1576 7.29412 6.30609 7.41067 6.42263C7.52721 6.53917 7.6757 6.61854 7.83735 6.65069C7.999 6.68285 8.16655 6.66635 8.31882 6.60327C8.4711 6.5402 8.60125 6.43339 8.69281 6.29635C8.78438 6.15931 8.83326 5.99819 8.83326 5.83337C8.83326 5.61236 8.74546 5.4004 8.58918 5.24412C8.4329 5.08784 8.22094 5.00004 7.99992 5.00004Z"
		// 		fill="#2A4B90"
		// 		style={{ fill: "#2A4B90", fillOpacity: 1 }}
		// 	/>
		// </svg>
	);
};

export default InfoRound;
