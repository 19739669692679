import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { StoreProvider, useStoreRehydrated } from "easy-peasy";
import { SnackbarProvider } from "notistack";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import "./App.css";
import { Box, CircularProgress } from "@mui/material";
//https://shome.auth.us-west-2.amazoncognito.com/login?client_id=7nkh2k7e5bqbf36gqo94v2f1bn&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F
// https://shome.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=7nkh2k7e5bqbf36gqo94v2f1bn&redirect_uri=http://localhost:3000/
// https://shome.auth.us-west-2.amazoncognito.com/login?client_id=7nkh2k7e5bqbf36gqo94v2f1bn&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F
Amplify.configure({
	Auth: {
		region: "us-west-2",
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
		identityPoolId: "us-west-2:16a264eb-7148-429d-ab81-5e3ab2b48cc9",
		authenticationFlowType: "USER_PASSWORD_AUTH",

		// oauth: {
		// 	domain: "https://shome.auth.us-west-2.amazoncognito.com",
		// 	scopes: ["email", "profile", "openid"],
		// 	redirectSignIn: "http://localhost:3000/",
		// 	redirectSignOut: "http://localhost:3000/",
		// 	responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
		// },
		// federationTarget: "COGNITO_USER_POOLS",

		// Cognito: {
		// 	//  Amazon Cognito User Pool ID
		// 	userPoolId: process.env.REACT_APP_USER_POOL_ID,
		// 	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		// 	userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
		// 	// OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
		// 	identityPoolId: "us-west-2:32772501-b2df-44d9-b40d-c567c9b742c0",
		// 	region: "us-west-2",
		// 	// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
		// 	// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
		// 	signUpVerificationMethod: "code", // 'code' | 'link'
		// 	loginWith: {
		// 		// OPTIONAL - Hosted UI configuration
		// 		oauth: {
		// 			domain: "https://shome.auth.us-west-2.amazoncognito.com",
		// 			scopes: [
		// 				"email",
		// 				"profile",
		// 				"openid",
		// 				"aws.cognito.signin.user.admin",
		// 			],
		// 			redirectSignIn: "http://localhost:3000/",
		// 			redirectSignOut: "http://localhost:3000/",
		// 			responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
		// 		},
		// 	},
		// },
	},
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const MainApp = () => {
	const rehydrated = useStoreRehydrated();
	if (!rehydrated) {
		return (
			<Box
				sx={{
					display: "flex",
					position: "absolute",
					left: "45%",
					top: "40%",
				}}
			>
				<CircularProgress size={100} />
			</Box>
		);
	}
	return (
		<BrowserRouter>
			<SnackbarProvider
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<App />
			</SnackbarProvider>
		</BrowserRouter>
	);
};

root.render(
	<React.StrictMode>
		<StoreProvider store={store}>
			<ThemeProvider theme={theme}>
				<MainApp />
			</ThemeProvider>
		</StoreProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
