import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import InfoLight from "../../svg-components/InfoLight";

interface InfoResultProps {
	title: string;
	description: string;
	setShowResultInfo?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InfoResult: React.FC<InfoResultProps> = ({
	title,
	description,
	setShowResultInfo,
}) => {
	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "start",
				padding: 2,
				borderRadius: "8px",
				backgroundColor: "#353E61",
				// maxWidth: "662px",
				marginBottom: "12px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: 1,
					color: "common.white",
					zIndex: 1,
				}}
			>
				{/* <InfoIcon fontSize="large" /> */}
				<InfoLight />
				<Typography
					// variant="h6"
					// component="h2"
					// sx={{ marginY: "auto" }}
					sx={{
						// width: "506px",
						// height: "19px",
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: 500,
						fontSize: "16px",
						lineHeight: "19px",
						color: "white", // or 'rgba(255, 255, 255, 1)'
						// flex: "none",
						// order: 2,
						// flexGrow: 1,
					}}
				>
					{title || "Information"}
				</Typography>
			</Box>
			<Typography
				// variant="body2"

				sx={{
					marginTop: 1,
					color: "#DADBE4",
					lineHeight: "18px",
					maxWidth: "100%",
					fontFamily: "Inter",
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "14px",
				}}
			>
				{description || "No description available"}
			</Typography>
			<IconButton
				aria-label="Close"
				sx={{
					position: "absolute",
					top: 8,
					right: 8,
					zIndex: 1,
					height: "32px",
					width: "32px",
					color: "common.white",
					"&:hover": {
						backgroundColor: "rgba(255, 255, 255, 0.1)",
						borderRadius: "4px",
					},
				}}
				onClick={() => setShowResultInfo && setShowResultInfo(false)}
			>
				<CloseIcon />
			</IconButton>
		</Box>
	);
};

export default InfoResult;
