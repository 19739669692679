import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LoginIcon from "@mui/icons-material/Login";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
// import UpgradeIcon from "@mui/icons-material/Upgrade";
// import CloseIcon from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/HighlightOff";
import UpgradeIcon from "@mui/icons-material/Upload";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { getSubscriptionService } from "../services/apiHelpers";

const UserAuth = () => {
	const { handleLogout, userName } = useAuth();
	const navigate = useNavigate();
	const [modalText, setModalText] = React.useState(
		"You are about to cancel your subscription. Are you sure?"
	);
	const [openModal, setOpenModal] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const { userInfo, getUserInfo } = useAuth();
	const [loadingCancel, setLoadingCancel] = React.useState(false);
	React.useEffect(() => {
		if (!userInfo) getUserInfo();
	}, [getUserInfo, userInfo]);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setModalText(
			"You are about to cancel your subscription. Are you sure?"
		);
		setLoadingCancel(false);
	};
	const handleCancelSubscription = async () => {
		setLoadingCancel(true);
		try {
			const service = await getSubscriptionService();
			const cancelSubscription = await service.cancelSubscription({
				email: userInfo.email,
			});
			if (cancelSubscription) {
				setModalText(
					"Your subscription has been successfully canceled."
				);
				setTimeout(() => {
					setOpenModal(false);
					window.location.reload();
				}, 1000);
			}
		} catch (error) {
			console.log(error);
			setModalText("Something went wrong. Please try again.");
		} finally {
			setLoadingCancel(false);
		}
	};
	const handlePricing = () => {
		navigate("/pricing");
	};
	const handleLogoutClick = () => {
		handleLogout();
		navigate("/");
		// if current page is pricing page, reload the page
		// if (window.location.pathname === "/pricing") {
		// 	window.location.reload();
		// } else {
		// 	navigate("/login");
		// }
	};
	return (
		<div>
			<Box display={"flex"} alignItems={"center"} gap={"8px"}>
				<Button
					startIcon={
						<AccountBoxIcon
							sx={{
								color: "#515151",
								fontSize: "32px",
								borderRadius: "6px",
								width: "32px",
								height: "32px",
							}}
						/>
					}
					endIcon={
						open ? (
							<ArrowDropUpIcon
								sx={{
									color: "#515151",
									fontSize: "32px",
									borderRadius: "6px",
									width: "32px",
									height: "32px",
								}}
							/>
						) : (
							<ArrowDropDownIcon
								sx={{
									color: "#515151",
									fontSize: "32px",
									borderRadius: "6px",
									width: "32px",
									height: "32px",
								}}
							/>
						)
					}
					sx={{
						borderRadius: "6px",
						// padding: "12px 0px",
						color: "#515151",
						textTransform: "capitalize",
					}}
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					<Typography
						variant="body1"
						fontSize={"14px"}
						fontFamily={"Inter"}
						color={"#515151"}
						fontWeight={"500"}
					>
						{userName}
					</Typography>
				</Button>
			</Box>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				sx={{
					top: "5px",
					"& .MuiMenu-paper": {
						boxShadow: "none !important",
						border: "1px solid #C9E2FF !important",
					},
				}}
			>
				{userInfo && userInfo?.subscriptionType !== "Super" && (
					<MenuItem
						onClick={handlePricing}
						sx={{ minWidth: "210px" }}
					>
						<UpgradeIcon
							sx={{ fontSize: "18px", color: "#447FFC" }}
						/>
						<Typography
							// onClick={() => handlePricing()}
							variant="body1"
							fontSize={"14px"}
							color={"#447FFC"}
							ml={1}
							fontFamily={"Inter"}
						>
							{userInfo && userInfo?.subscriptionType === "Free"
								? "Upgrade to Starter"
								: "Upgrade to Super"}
						</Typography>
					</MenuItem>
				)}
				{userInfo && userInfo?.subscriptionType !== "Free" && (
					<MenuItem
						sx={{ minWidth: "210px" }}
						onClick={handleOpenModal}
					>
						<CloseIcon
							sx={{ fontSize: "18px", color: "#515151" }}
						/>
						<Typography
							variant="body1"
							fontSize={"14px"}
							color={"#515151"}
							ml={1}
							fontFamily={"Inter"}
						>
							{"Cancel Subscription"}
						</Typography>
					</MenuItem>
				)}

				<MenuItem sx={{ minWidth: "210px", cursor: "default" }}>
					<CardMembershipIcon
						sx={{ fontSize: "18px", color: "#515151" }}
					/>
					<Typography
						variant="body1"
						fontSize={"14px"}
						color={"#515151"}
						ml={1}
						fontFamily={"Inter"}
					>
						{userInfo?.subscriptionType}
					</Typography>
				</MenuItem>
				<MenuItem
					onClick={handleLogoutClick}
					sx={{ minWidth: "210px" }}
				>
					<LoginIcon sx={{ fontSize: "18px", color: "#EB5757" }} />
					<Typography
						variant="body1"
						fontSize={"14px"}
						color={"#EB5757"}
						ml={1}
						fontFamily={"Inter"}
					>
						Log Out
					</Typography>
				</MenuItem>
			</Menu>

			<React.Fragment>
				<Dialog
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Cancel Subscription!"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							sx={{
								fontSize: "16px",
								color: "#515151",
								textAlign: "center",
								lineHeight: "1.5",
							}}
						>
							{modalText}
						</DialogContentText>
					</DialogContent>

					<DialogActions>
						{!loadingCancel && (
							<Button onClick={handleCloseModal}>
								Keep Searching
							</Button>
						)}
						{loadingCancel ? (
							<CircularProgress color="inherit" size={"20px"} />
						) : (
							<Button
								sx={{
									color: "#EB5757",
								}}
								onClick={handleCancelSubscription}
								autoFocus
							>
								Cancel Subscription
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</React.Fragment>
		</div>
	);
};

export default UserAuth;
