import { useStoreActions, useStoreState } from 'easy-peasy';
import { useSnackbar } from "notistack";
// import { ChatHistoryResponse } from "../openapi";
import { getChatHistoryService, getTagService } from "../services/apiHelpers";
import useHistory from "./useHistory";
import usePreview from './usePreview';
import { EnhancedTagResponse, ISingleDataset } from '../redux/models/datasetModel';

const useDataset = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getChatHistory, addChatHistory } = useHistory()
  const { getPreviewProcessResult } = usePreview()

  /** REDUX */
  const datasetState = useStoreState((state: any) => state.dataset)
  const datasetActions = useStoreActions((actions: any) => actions.dataset)

  // const historyState = useStoreState((state: any) => state.chatHistory)
  // const historyActions = useStoreActions((actions: any) => actions.chatHistory)

  const setFeedbackData = useStoreActions((actions: any) => actions.feedback.setData)
  const getCategories = async () => {
    try {
      const service = await getTagService();
      const response = await service.getTags('Category', "100", "0");
      if (response.status === 200 || response.status === 201) {
        const allCat = response.data.tags.filter((tag) => tag.vectorClass !== "Test_db");
        datasetActions.setAllCategoriesData(allCat)
        if (allCat && allCat.length > 0 && datasetState.queryType) {
          const currentCategory = allCat?.find(
            (category: any) => category.vectorClass === datasetState.queryType
          );
          if (
            currentCategory &&
            currentCategory?.additionalInfoSimilar
          ) {
            const qs = currentCategory?.additionalInfoSimilar.map(
              (item: any) => item.similarquestion as string
            );
            if (qs) {
              // console.log("additionalInfoSimilar questions", qs);
              datasetActions.setRandomQuestions(getRandomThreeQuestions(qs));
            }
          }
          return allCat as EnhancedTagResponse[]
        }
      }
      return []
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
    }
  }

  // const setPoplarQuestions = async (currentQueryType?: string , ) => {
  //   try {
  //     const catrgories = datasetState.allCategoriesData
  //       ? datasetState.allCategoriesData
  //       : await getCategories();
  //     if (!currentQueryType && catrgories && catrgories.length > 0) {
  //       currentQueryType = catrgories[0].vectorClass;
  //     }
  //     if (currentQueryType) {
  //       setQuryType(currentQueryType);
  //     }
  //     const newCurrentCategory = catrgories?.find(
  //       (category:any) => category.vectorClass === currentQueryType
  //     );
  //     console.log("newCurrentCategory: ", newCurrentCategory);
  //     if (
  //       newCurrentCategory &&
  //       newCurrentCategory?.additionalInfoSimilar
  //     ) {
  //       const qs = newCurrentCategory?.additionalInfoSimilar.map(
  //         (item: any) => item.similarquestion as string
  //       );
  //       if (qs) {
  //         console.log("additionalInfoSimilar questions", qs);
  //         setRandomQuestions(getRandomThreeQuestions(qs));
  //       }
  //     } 
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };


  const getRandomThreeQuestions = (qsource?: string[]) => {
    const questionSource: string[] = qsource ? qsource : datasetState.randomQuestions;
    const selectedQuestions: string[] = [];
    const limit =
      questionSource && questionSource.length > 3
        ? 3
        : questionSource.length;
    while (selectedQuestions.length < limit) {
      const randomIndex = Math.floor(
        Math.random() * questionSource.length
      );
      const randomQuestion = questionSource[randomIndex];
      if (!selectedQuestions.includes(randomQuestion)) {
        selectedQuestions.push(randomQuestion);
      }
    }
    return selectedQuestions;
  };
  const getDataset = async (question: string) => {
    if (!question || datasetState.isLoading) return;
    datasetActions.createNewQuestion(question)
    const threadId = Number(datasetState.threadId)

    addChatHistory({
      question,
      threadId,
    })

    try {
      const service = await getChatHistoryService();
      // send customHeaders
      const response = await service.getSearchResult({
        question,
        threadId,
        queryType: datasetState.queryType
      });
      if (response.status === 200 || response.status === 201) {
        let responseData = response.data
        // console.log('responseData=', responseData)
        // const result = responseData.result
        // // if (result && result?.length > 0) {
        // //   const resultWithPreview = await getPreviewProcessResult(result)
        // //   responseData.result = await resultWithPreview;
        // //   // console.log('responseData=', responseData)
        // // }
        datasetActions.setShowNoResultText(false)
        datasetActions.setExaDataset(false)
        datasetActions.updateAnswer(responseData)
        // if (responseData?.historyId) {
        //   getSummary(responseData.historyId, responseData.shortSummary)
        // }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      datasetActions.updateAnswer({})
    }
  };

  const getExaDataset = async (question: string) => {
    if (!question || datasetState.isLoading) return;
    // get random threadId number
    const threadId = Math.floor(Math.random() * 100) + 1
    datasetActions.setLoading(true)
    datasetActions.setExaSearching(true)
    try {
      const service = await getChatHistoryService();
      const response = await service.getExaSearchResult({
        question,
        threadId,
        queryType: datasetState.queryType
      });
      if (response.status === 200 || response.status === 201) {
        let responseData = response.data
        datasetActions.setExaDataset(true)
        datasetActions.updateAnswer(responseData)
      } else {
        datasetActions.updateAnswer({})
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      datasetActions.updateAnswer({})
    } finally {
      datasetActions.setLoading(false)
      datasetActions.setExaSearching(false)
    }
  }
  /** THIS METHOD WILL GET SUMMARY */
  const getSummary = async (threadId: number, shortSummary: any) => {
    try {
      const service = await getChatHistoryService();
      const response = await service.getTakeawaysByChId(threadId);
      datasetActions.updateSummary(response.data.takeaways.length ? response.data.takeaways : shortSummary)
      getChatHistory()
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      datasetActions.updateSummary(shortSummary)
    }
  };

  const getThreadId = async () => {
    try {
      const service = await getChatHistoryService();
      const response = await service.getThreadId();
      datasetActions.setThreadId(response.data)
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      datasetActions.setThreadId(-1)
    } finally {
      datasetActions.setLoading(false)
    }
  };

  // const handleReOrderingHistory = (threadId: number) => {
  //   const filterData: ChatHistoryResponse[] = []
  //   const otherData: ChatHistoryResponse[] = []
  //   if (historyState.historyData && historyState.historyData?.length > 0) {
  //     historyState.historyData.forEach((element: ChatHistoryResponse) => {
  //       if (element.threadId === threadId) {
  //         filterData.push(element)
  //       } else {
  //         otherData.push(element)
  //       }
  //     })
  //     historyActions.setData({
  //       historyData: [...filterData, ...otherData]
  //     })
  //   }
  // }


  const getChatHistoryByThreadId = async (threadId: number) => {
    if (datasetState.isLoading) return;
    if (!threadId) {
      threadId = 100
    }
    datasetActions.setThreadId(threadId)
    datasetActions.setHistory(undefined)
    datasetActions.setLoading(true)
    datasetActions.setShowNoResultText(false)
    datasetActions.setExaDataset(false)
    // handleReOrderingHistory(threadId)
    try {
      const service = await getChatHistoryService();
      const response = await service.getThreadHistoryByUser(threadId?.toString() ?? '0');
      if (response.status === 200 || response.status === 201) {
        const responseData = response.data.history
        // console.log('getChatHistoryByThreadId=', responseData)
        const previewPromise: any = [];
        // for (let i = 0; responseData.length > i; i++) {
        //   const resultWithPreview = getPreviewProcessResult(responseData[i].searchResponse.result)
        //   previewPromise.push(resultWithPreview)
        // }
        // for (let i = 0; responseData.length > i; i++) {
        const resultWithPreview = getPreviewProcessResult(responseData[0].searchResponse.result)
        previewPromise.push(resultWithPreview)
        // }
        const allResults = await Promise.allSettled(previewPromise)

        // const processResult = responseData.map((element: ChatHistoryResponse, index: number) => {
        //   const promiseResolve = allResults[index]
        //   const historyData: ISingleDataset = {
        //     loading: false,
        //     historyId: element.id,
        //     question: element.question,
        //     shortDescription: element.searchResponse.shortDescription,
        //     shortSummary: element.searchResponse.takeaways ?? element.searchResponse.shortSummary,
        //     summaryLoading: false,
        //     result: promiseResolve.status === 'fulfilled' ? promiseResolve.value : [],
        //   }
        //   return historyData;
        // })

        // const processResult = responseData.map((element: ChatHistoryResponse, index: number) => {
        const promiseResolve = allResults[0]
        const historyData: ISingleDataset = {
          loading: false,
          historyId: responseData[0].id,
          question: responseData[0].question,
          shortDescription: responseData[0].searchResponse.shortDescription,
          shortSummary: responseData[0].searchResponse.takeaways ?? responseData[0].searchResponse.shortSummary,
          summaryLoading: false,
          result: promiseResolve.status === 'fulfilled' ? promiseResolve.value : [],
        }
        // return historyData;
        // })
        // datasetActions.setHistory(processResult)
        datasetActions.setHistory([historyData])
        setFeedbackData(responseData)
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message?.toString() ?? error.toString(), {
        variant: "error",
      });
    } finally {
      datasetActions.setLoading(false)
    }
  };
  const clearDataset = () => {
    datasetActions.setShowNoResultText(false)
    datasetActions.setExaDataset(false)
    datasetActions.clearData()
  }
  const clearQuestion = () => {
    datasetActions.question()
  }
  const questionReplaceHandler = (isReplaceQuestion: boolean, question: string) => {
    datasetActions.setReplaceQuestion({
      isReplaceQuestion,
      question
    })
  }

  const setMaxSearchLimit = (maxSearchLimit: boolean) => {
    datasetActions.setMaxSearchLimit(maxSearchLimit)
  }
  const setShowPopularQusetion = (showPopularQusetions: boolean) => {
    datasetActions.setShowPopularQusetion(showPopularQusetions)
  }
  const setRandomQuestions = (randomQuestions: string[]) => {
    datasetActions.setRandomQuestions(randomQuestions)
  }
  const setShowNoResultText = (showNoResultText: boolean) => {
    datasetActions.setShowNoResultText(showNoResultText)
  }
  const setQuryType = (queryType: string) => {
    datasetActions.setQuryType(queryType)
  }
  return {
    queryType: datasetState?.queryType,
    sateQuestion: datasetState?.question,
    datasets: datasetState?.datasets,
    isLoading: datasetState.isLoading,
    threadId: datasetState.threadId,
    isReplaceQuestion: datasetState.isReplaceQuestion,
    maxSearchLimit: datasetState.maxSearchLimit,
    showPopularQusetion: datasetState.showPopularQusetions,
    randomQuestions: datasetState.randomQuestions,
    isExaDataset: datasetState.isExaDataset,
    exaSearching: datasetState.exaSearching,
    showNoResultText: datasetState.showNoResultText,
    allCategoriesData: datasetState.allCategoriesData as EnhancedTagResponse[],
    setRandomQuestions,
    setShowPopularQusetion,
    setMaxSearchLimit,
    getDataset,
    getExaDataset,
    clearDataset,
    getThreadId,
    getChatHistoryByThreadId,
    clearQuestion,
    questionReplaceHandler,
    setShowNoResultText,
    getSummary,
    setQuryType,
    getCategories,
    getRandomThreeQuestions
  };
};
export default useDataset;

