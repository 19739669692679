import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { IResetPasswordStep1Form } from "../../interfaces/IResetPasswordStep1Form";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import FormTitle from "./FormTitle";

const ForgotPasswordForm = () => {
	const navigate = useNavigate();
	const { showSnackbar } = useAlert();
	const { loading, error, setError, getResetPasswordCodeByEmail } = useAuth();

	const resetPasswordInitialValue: IResetPasswordStep1Form = {
		email: "",
	};

	const [initialValues, setInitialValues] = useState(
		resetPasswordInitialValue
	);

	const validationSchema = yup.object().shape({
		email: yup.string().email("Invalid email").required().label("Email"),
	});

	const onSubmit = async (
		values: IResetPasswordStep1Form,
		actions: FormikHelpers<IResetPasswordStep1Form>
	) => {
		try {
			actions.setSubmitting(true);
			setInitialValues(values);
			await getResetPasswordCodeByEmail(values);
		} catch (error: any) {
			showSnackbar(error?.message, error);
		} finally {
			actions.setSubmitting(false);
		}
	};

	const handleSignInClick = () => {
		navigate("/");
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			width={"100%"}
		>
			<FormWrapper>
				{!loading && error && (
					<FormAlert error={error} setError={setError} />
				)}
				<FormTitle title={"Forgot Password"} />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{() => {
						return (
							<Form
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<FormField
									name="email"
									label="Email"
									placeholder="Email"
									marginBottom="0px"
								/>
								<FormButton
									label={"Submit"}
									loading={loading}
								/>
								<Typography
									textAlign="center"
									color={"#A6A8AC"}
									fontSize={"12px"}
									lineHeight={"24px"}
									fontFamily={"Inter"}
									fontWeight={500}
								>
									Back to
									<Typography
										component={"span"}
										// mx={1}
										// color="primary"
										display="inline-block"
										onClick={handleSignInClick}
										sx={{
											cursor: "pointer",
											color: "#1472FF",
											fontWeight: 500,
											fontFamily: "Inter",
											fontSize: "12px",
											lineHeight: "24px",
											textAlign: "center",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
									>
										&nbsp;Login&nbsp;
									</Typography>
								</Typography>
							</Form>
						);
					}}
				</Formik>
			</FormWrapper>
		</Box>
	);
};

export default ForgotPasswordForm;
