import React from "react";

const InfoLight = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="16"
				cy="16"
				r="16"
				fill="white"
				fillOpacity="0.1"
				style={{ fill: "white", fillOpacity: 0.1 }}
			/>
			<path
				d="M20.2413 8.35002C19.4749 7.71967 18.5774 7.26837 17.6143 7.02905C16.6513 6.78973 15.647 6.76842 14.6746 6.96668C13.3799 7.2282 12.1921 7.86841 11.2619 8.80608C10.3316 9.74376 9.70085 10.9366 9.44963 12.2333C9.26616 13.2056 9.2995 14.2064 9.54726 15.1643C9.79502 16.1222 10.2511 17.0136 10.883 17.775C11.4694 18.4367 11.8055 19.2829 11.833 20.1667V22.6667C11.833 23.3297 12.0964 23.9656 12.5652 24.4345C13.034 24.9033 13.6699 25.1667 14.333 25.1667H17.6663C18.3293 25.1667 18.9652 24.9033 19.4341 24.4345C19.9029 23.9656 20.1663 23.3297 20.1663 22.6667V20.325C20.1943 19.3493 20.5527 18.412 21.183 17.6667C22.2872 16.3007 22.8078 14.554 22.6314 12.8064C22.4551 11.0587 21.5962 9.45126 20.2413 8.33335V8.35002ZM18.4996 22.6667C18.4996 22.8877 18.4118 23.0997 18.2555 23.2559C18.0993 23.4122 17.8873 23.5 17.6663 23.5H14.333C14.1119 23.5 13.9 23.4122 13.7437 23.2559C13.5874 23.0997 13.4996 22.8877 13.4996 22.6667V21.8333H18.4996V22.6667ZM19.8913 16.6333C19.0534 17.6272 18.5645 18.8684 18.4996 20.1667H16.833V17.6667C16.833 17.4457 16.7452 17.2337 16.5889 17.0774C16.4326 16.9211 16.2206 16.8333 15.9996 16.8333C15.7786 16.8333 15.5667 16.9211 15.4104 17.0774C15.2541 17.2337 15.1663 17.4457 15.1663 17.6667V20.1667H13.4996C13.4776 18.8901 13.0054 17.6623 12.1663 16.7C11.6127 16.0367 11.2404 15.2412 11.0857 14.3911C10.9311 13.5411 10.9993 12.6654 11.2839 11.8496C11.5684 11.0338 12.0595 10.3056 12.7092 9.73612C13.359 9.16661 14.1452 8.77518 14.9913 8.60002C15.7184 8.4503 16.4698 8.46439 17.1908 8.64124C17.9118 8.8181 18.5843 9.15329 19.1597 9.62248C19.735 10.0917 20.1986 10.6831 20.5169 11.3538C20.8351 12.0245 21 12.7576 20.9996 13.5C21.0058 14.6416 20.6139 15.7496 19.8913 16.6333V16.6333Z"
				fill="white"
				style={{ fill: "white", fillOpacity: 1 }}
			/>
		</svg>

		// <svg
		// 	width="24"
		// 	height="24"
		// 	viewBox="0 0 24 24"
		// 	fill="none"
		// 	xmlns="http://www.w3.org/2000/svg"
		// >
		// 	<circle
		// 		cx="12"
		// 		cy="12"
		// 		r="12"
		// 		fill="white"
		// 		style={{ fill: "white", fillOpacity: 1 }}
		// 	/>
		// 	<path
		// 		d="M15.3931 5.87996C14.78 5.37569 14.062 5.01465 13.2916 4.82319C12.5211 4.63174 11.7177 4.61469 10.9398 4.7733C9.90405 4.98251 8.95379 5.49468 8.20959 6.24482C7.46538 6.99496 6.96078 7.94925 6.7598 8.98663C6.61303 9.76445 6.6397 10.5651 6.83791 11.3314C7.03611 12.0977 7.40097 12.8109 7.90647 13.42C8.37559 13.9493 8.64449 14.6263 8.66647 15.3333V17.3333C8.66647 17.8637 8.87718 18.3724 9.25225 18.7475C9.62732 19.1226 10.136 19.3333 10.6665 19.3333H13.3331C13.8636 19.3333 14.3723 19.1226 14.7473 18.7475C15.1224 18.3724 15.3331 17.8637 15.3331 17.3333V15.46C15.3555 14.6794 15.6423 13.9296 16.1465 13.3333C17.0299 12.2405 17.4463 10.8432 17.3052 9.44505C17.1642 8.04692 16.477 6.76096 15.3931 5.86663V5.87996ZM13.9998 17.3333C13.9998 17.5101 13.9296 17.6797 13.8045 17.8047C13.6795 17.9297 13.5099 18 13.3331 18H10.6665C10.4897 18 10.3201 17.9297 10.1951 17.8047C10.07 17.6797 9.9998 17.5101 9.9998 17.3333V16.6666H13.9998V17.3333ZM15.1131 12.5066C14.4428 13.3017 14.0517 14.2947 13.9998 15.3333H12.6665V13.3333C12.6665 13.1565 12.5962 12.9869 12.4712 12.8619C12.3462 12.7369 12.1766 12.6666 11.9998 12.6666C11.823 12.6666 11.6534 12.7369 11.5284 12.8619C11.4034 12.9869 11.3331 13.1565 11.3331 13.3333V15.3333H9.9998C9.98221 14.312 9.60443 13.3298 8.93313 12.56C8.49023 12.0293 8.19239 11.3929 8.06867 10.7129C7.94495 10.0328 7.99957 9.33231 8.22719 8.67965C8.45481 8.02699 8.84768 7.44446 9.36748 6.98885C9.88728 6.53324 10.5163 6.2201 11.1931 6.07996C11.7748 5.96019 12.3759 5.97146 12.9527 6.11294C13.5295 6.25443 14.0676 6.52258 14.5278 6.89793C14.9881 7.27328 15.359 7.74642 15.6136 8.28298C15.8682 8.81954 16.0001 9.40606 15.9998 9.99996C16.0047 10.9132 15.6912 11.7996 15.1131 12.5066Z"
		// 		fill="#353E61"
		// 		style={{ fill: "#353E61", fillOpacity: 1 }}
		// 	/>
		// </svg>
	);
};

export default InfoLight;
