const ThreeDotsMenu = () => {
	return (
		<svg
			width="16"
			height="4"
			viewBox="0 0 16 4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2Z"
				fill="#2A4B90"
				style={{ fill: "#2A4B90", fillOpacity: 1 }}
			/>
		</svg>

		// <svg
		// 	width="18"
		// 	height="4"
		// 	viewBox="0 0 18 4"
		// 	fill="none"
		// 	xmlns="http://www.w3.org/2000/svg"
		// >
		// 	<path
		// 		d="M-8.74228e-08 2C-6.42368e-08 2.53043 0.210714 3.03914 0.585786 3.41421C0.960859 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.96086 3.41421 0.585787C3.03914 0.210714 2.53043 1.2781e-07 2 1.50996e-07C1.46957 1.74182e-07 0.960859 0.210714 0.585786 0.585787C0.210713 0.96086 -1.10609e-07 1.46957 -8.74228e-08 2ZM7 2C7 2.53043 7.21071 3.03914 7.58579 3.41421C7.96086 3.78929 8.46957 4 9 4C9.53043 4 10.0391 3.78929 10.4142 3.41421C10.7893 3.03914 11 2.53043 11 2C11 1.46957 10.7893 0.960859 10.4142 0.585787C10.0391 0.210714 9.53043 -1.7817e-07 9 -1.54984e-07C8.46957 -1.31798e-07 7.96086 0.210714 7.58579 0.585787C7.21071 0.960859 7 1.46957 7 2ZM14 2C14 2.53043 14.2107 3.03914 14.5858 3.41421C14.9609 3.78929 15.4696 4 16 4C16.5304 4 17.0391 3.78929 17.4142 3.41421C17.7893 3.03914 18 2.53043 18 2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 -4.8415e-07 16 -4.60964e-07C15.4696 -4.37778e-07 14.9609 0.210714 14.5858 0.585786C14.2107 0.960859 14 1.46957 14 2Z"
		// 		fill="black"
		// 		fillOpacity="0.5"
		// 	/>
		// </svg>
	);
};

export default ThreeDotsMenu;
