import { getChatHistoryService } from "../services/apiHelpers";
import { UserFeedbackRequest } from "../openapi";
import { useSnackbar } from "notistack";
import { useStoreActions, useStoreState } from "easy-peasy";
import { IFeedbackType } from "../redux/models/datasetModel";
import { ISubmitFeedback } from "../redux/models/feedbackModel";

const useFeedback = (historyId?: number | undefined) => {
  const { enqueueSnackbar } = useSnackbar();

  const feedbackData = useStoreState((state: any) => state.feedback.data)
  const feedbackActions = useStoreActions((actions: any) => actions.feedback)

  const submitHandler = async (requestBody: UserFeedbackRequest) => {
    try {
      const service = await getChatHistoryService();
      const response = await service.saveUserFeedback(requestBody);
      if (response.status === 200 || response.status === 201) {
        const payload: ISubmitFeedback = {
          id: requestBody.id,
          feedback: requestBody.isHelpful ? IFeedbackType.POSITIVE : IFeedbackType.NEGATIVE
        }
        feedbackActions.setFeedback(payload)
        return true;
      }
      return false;
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
        variant: "error",
      });
      return false;
    }
  };




  return {
    oldFeedback: historyId ? feedbackData[historyId] : undefined,
    submitHandler,
  };
};

export default useFeedback;
