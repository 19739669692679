import React from "react";

const Tick = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.81326 5.86004L6.95326 8.72671L5.85325 7.62671C5.79349 7.55692 5.71994 7.50024 5.63723 7.46022C5.55452 7.42021 5.46444 7.39772 5.37262 7.39418C5.28081 7.39063 5.18926 7.4061 5.10371 7.43962C5.01816 7.47313 4.94046 7.52397 4.87549 7.58894C4.81052 7.65391 4.75968 7.73161 4.72616 7.81716C4.69265 7.90271 4.67718 7.99426 4.68072 8.08608C4.68427 8.17789 4.70676 8.26798 4.74677 8.35069C4.78679 8.4334 4.84347 8.50694 4.91325 8.56671L6.47992 10.14C6.54222 10.2018 6.61609 10.2507 6.69732 10.2839C6.77854 10.3171 6.86552 10.3339 6.95326 10.3334C7.12815 10.3326 7.29575 10.2632 7.41992 10.14L10.7533 6.80671C10.8157 6.74473 10.8653 6.671 10.8992 6.58976C10.933 6.50852 10.9505 6.42138 10.9505 6.33337C10.9505 6.24537 10.933 6.15823 10.8992 6.07699C10.8653 5.99575 10.8157 5.92202 10.7533 5.86004C10.6283 5.73587 10.4594 5.66618 10.2833 5.66618C10.1071 5.66618 9.93816 5.73587 9.81326 5.86004ZM7.99992 1.33337C6.68138 1.33337 5.39245 1.72437 4.29612 2.45691C3.19979 3.18945 2.34531 4.23064 1.84072 5.44882C1.33614 6.66699 1.20412 8.00744 1.46135 9.30064C1.71859 10.5938 2.35353 11.7817 3.28588 12.7141C4.21823 13.6464 5.40611 14.2814 6.69932 14.5386C7.99253 14.7958 9.33297 14.6638 10.5511 14.1592C11.7693 13.6547 12.8105 12.8002 13.5431 11.7038C14.2756 10.6075 14.6666 9.31858 14.6666 8.00004C14.6666 7.12456 14.4942 6.25765 14.1591 5.44882C13.8241 4.63998 13.333 3.90505 12.714 3.286C12.0949 2.66694 11.36 2.17588 10.5511 1.84084C9.74231 1.50581 8.8754 1.33337 7.99992 1.33337ZM7.99992 13.3334C6.94509 13.3334 5.91394 13.0206 5.03688 12.4345C4.15982 11.8485 3.47623 11.0156 3.07256 10.041C2.6689 9.06648 2.56328 7.99412 2.76907 6.95956C2.97485 5.92499 3.48281 4.97468 4.22869 4.2288C4.97457 3.48292 5.92487 2.97497 6.95944 2.76919C7.99401 2.5634 9.06636 2.66902 10.0409 3.07268C11.0154 3.47635 11.8484 4.15994 12.4344 5.037C13.0205 5.91406 13.3333 6.94521 13.3333 8.00004C13.3333 9.41453 12.7714 10.7711 11.7712 11.7713C10.771 12.7715 9.41441 13.3334 7.99992 13.3334Z"
				fill="#7A7C80"
				style={{ fill: "#7A7C80", fillOpacity: 1 }}
			/>
		</svg>
	);
};

export default Tick;
