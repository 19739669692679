import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import StarIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/system";
// import NewSmallLogo from "../svg-components/NewSmallLogo";
// import FullLogo from "../svg-components/FullLogo";
// import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Header from "../layout/Header";
import DoneIcon from "@mui/icons-material/Done";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, SyntheticEvent, useEffect } from "react";
import FullLogoLarger from "../svg-components/FullLogoLarger";
// import useSubscription from "../hooks/useSubscription";
import useAuth from "../hooks/useAuth";
import { getSubscriptionService } from "../services/apiHelpers";
const PricingList = styled("ul")({
	// margin: 0,
	// padding: 0,
	listStyle: "none",
	display: "flex",
	flexDirection: "column",
	alignItems: "baseline",
	justifyContent: "center",
	// gap: "8px",
});

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, color: "#008a00" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const Pricing = () => {
	const tiers = [
		{
			title: "Free",
			id: "free",
			yearlyId: "free",
			price: "0",
			yearlyPrice: "0",
			description: [
				"10 searches per month",
				"Email support finding resources",
				// "Help center access",
				// "Email support",
			],
			buttonText: "Keep Searching",
			buttonVariant: "outlined",
		},
		{
			title: "Starter",
			id: "price_1OpjxFAOnGsxYfoUFquJFvCV",
			yearlyId: "price_1Opk4xAOnGsxYfoU2t00GkI3",
			// id: "price_1Or3ldAOnGsxYfoUlIK38TIl", //test
			// yearlyId: "price_1Or3ldAOnGsxYfoUlIK38TIl", //test
			subheader: "Most popular",
			price: "12.50",
			yearlyPrice: "10",
			description: [
				"25 searches per month",
				"Priority email support finding resources",
				// "Help center access",
				// "Priority email support",
			],
			buttonText: "Upgrade to Starter",
			buttonVariant: "contained",
		},
		{
			title: "Super",
			id: "price_1Opk6vAOnGsxYfoUd517hZ1V",
			yearlyId: "price_1Opk7GAOnGsxYfoUwzuugCpn",
			// id: "price_1Or3lQAOnGsxYfoUrbbPSDLG", //test
			// yearlyId: "price_1Or3lQAOnGsxYfoUrbbPSDLG", //test
			price: "40",
			yearlyPrice: "30",
			description: [
				"Unlimited searches per month",
				"Priority email support finding resources",
				// "Priority email support",
				// "Phone & email support",
			],
			buttonText: "Upgrade to Super",
			buttonVariant: "outlined",
		},
	];
	const [value, setValue] = useState(0);
	// useEffect(() => {

	// 	}, [])
	// const {
	//   isLoading,
	//   userInfo,
	//   starterUrl,
	//   superUrl,
	//   getSubscriptionInfo,
	//   clearSubscriptionInfo,
	//   getPriceUrls
	// } = useSubscription();
	// if(!userInfo) {
	// 	getSubscriptionInfo()
	// }
	const [stripeId, setStripeId] = useState("");
	const { userInfo } = useAuth();
	const getStripeId = async () => {
		if (userInfo && !userInfo.stripeCustomerId) {
			try {
				const service = await getSubscriptionService();
				const getcustomerStripeId = await service.getStripeCustomer({
					email: userInfo.email,
					name: userInfo.firstName,
				});
				// console.log(getcustomerStripeId);
				if ((getcustomerStripeId.data as { id: string })?.id) {
					setStripeId(
						(getcustomerStripeId.data as { id: string }).id
					);
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			// console.log(userInfo.stripeCustomerId);
			setStripeId(userInfo.stripeCustomerId);
		}
	};
	useEffect(() => {
		getStripeId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// useEffect(() => {
	// 	console.log("stripeId", stripeId);
	// }, [stripeId]);
	const handleButtonClick = async (priceId: string) => {
		if (userInfo && userInfo.subscriptionType === "Premium") {
			window.location.href = window.location.origin;
			return;
		}
		if (!stripeId || stripeId === "" || !priceId) return;
		if (priceId === "free") {
			window.location.href = window.location.origin;
			return;
		}
		try {
			const service = await getSubscriptionService();
			const response = await service.createSubscriptionSession({
				priceId: priceId,
				customerId: stripeId,
			});
			if (response.status === 200 || response.status === 201) {
				const responseData: { url: string } = response.data as {
					url: string;
				};
				window.location.href = responseData.url;
			}
		} catch (error: any) {
			console.log(error);
		}
	};
	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	// const navigate = useNavigate();
	return (
		<Box>
			<Header />
			<Box
				sx={{
					padding: "40px 150px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					// gap: "30px",
					// height: "100vh",
					// ".icon-404": {
					// 	width: { xs: "100%", sm: "600px", md: "600px" },
					// 	height: { xs: "200px", sm: "300px", md: "400px" },
					// },
				}}
			>
				<Link
					to="/"
					style={{
						marginBottom: "18px",
						display: "flex",
						alignItems: "center",
						gap: "10px",
						color: "#437ffc",
						textDecoration: "none",
					}}
				>
					<FullLogoLarger />
					{/* 
				<Typography
					variant="h1"
					color="#437ffc"
					sx={{ fontWeight: 700 }}
				>
					Home
				</Typography> */}
				</Link>
				<Box sx={{ color: "#E5E5E0" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
							sx={{ color: "#E5E5E0" }}
						>
							<Tab
								sx={{
									color: "#515151",
									textTransform: "none",
									fontSize: "14px",
								}}
								label="Monthly"
								{...a11yProps(0)}
							/>
							<Tab
								sx={{
									color: "#515151",
									textTransform: "none",
									fontSize: "14px",
								}}
								label="Yearly"
								{...a11yProps(1)}
							/>
						</Tabs>
					</Box>
					<CustomTabPanel value={value} index={0}>
						{" "}
						&nbsp;
					</CustomTabPanel>
					<CustomTabPanel value={value} index={1}>
						Save 25% with yearly
					</CustomTabPanel>
				</Box>

				<Grid
					container
					spacing={5}
					alignItems="center"
					justifyContent={"center"}
				>
					{tiers.map((tier) => (
						<Grid
							item
							key={tier.title}
							xs={12}
							// sm={tier.title === "Enterprise" ? 12 : 6}
							// padding={"0px"}
							// display={"flex"}
							// justifyContent={"center"}
							// alignItems={"center"}
							// width={"30%"}
							// lg={4}
							md={4}
						>
							<Card
								sx={{
									borderRadius: "8px",
									border: "1px solid #E5E5E0",
									boxShadow:
										"0px 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 1.5px 1.5px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 1.5px 0px rgba(0, 0, 0, 0.10) inset, 0px 0px 22px 0px rgba(0, 0, 0, 0.10)",
									background: "#fff",
									padding: "0px",
								}}
							>
								<CardHeader
									title={tier.title}
									// subheader={tier.subheader}
									titleTypographyProps={{
										align: "center",
										fontSize: "16px",
										fontWeight: 700,
									}}
									sx={{
										backgroundColor: (theme) =>
											theme.palette.mode === "light"
												? "#447FFC"
												: "#447FFC",
										color: "#fff",
										marginBottom: "16px",
									}}
								/>
								<CardContent>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "baseline",
											marginBottom: "16px",
										}}
									>
										<Typography
											component="h4"
											variant="h4"
											color="#515151"
										>
											$
											{value === 0
												? tier.price
												: tier.yearlyPrice}
										</Typography>
										<Typography
											variant="h6"
											color="#515151"
										>
											/mo
										</Typography>
									</Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<PricingList>
											{tier.description.map((line) => (
												<Typography
													component="li"
													fontSize={"12px"}
													key={line}
													color={"#4D4E53"}
													display={"flex"}
													alignItems={"flex-start"}
													justifyContent={"center"}
													gap={"4px"}
												>
													<DoneIcon
														sx={{
															color: "#0047DC",
														}}
													/>
													<Typography
														fontSize={"12px"}
														letterSpacing={
															"-0.02em"
														}
													>
														{line}
													</Typography>
												</Typography>
											))}
										</PricingList>
									</Box>
								</CardContent>
								<CardActions>
									<Button
										// disabled={
										// 	userInfo &&
										// 	userInfo.subscriptionType ===
										// 		"Premium"
										// }
										sx={{
											fontVariantCaps: "titling-caps",
											// textTransform: "capitalize",
											padding: "6px",
											margin: "0% 8% 16px 8%",
											// height: "36px",
											// margin: "0px 16px",
											fontSize: "13px",
											fontWeight: 600,
											lineHeight: "24px",
											fontFamily: "Inter",
											textTransform: "none",
											// backgroundColor: "#ffffff",
											// color: "#447FFC",
											textDecorationStyle: "none",
											backgroundColor: "#447FFC",
											color: "#ffffff",
											":hover": {
												// backgroundColor: "#447FFC",
												// color: "#ffffff",

												backgroundColor: "#ffffff",
												color: "#447FFC",
											},
										}}
										fullWidth
										variant={"outlined"}
										onClick={() => {
											handleButtonClick(
												value === 0
													? tier.id
													: tier.yearlyId
											);
										}}
									>
										{userInfo &&
										userInfo.subscriptionType === "Premium"
											? "Keep Searching"
											: userInfo &&
											  userInfo.subscriptionType ===
													tier.title
											? "Keep Searching"
											: tier.title !== "Free" &&
											  userInfo &&
											  userInfo.subscriptionType ===
													"Free"
											? tier.buttonText
											: tier.title === "Free" &&
											  userInfo &&
											  userInfo.subscriptionType !==
													"Free"
											? "Downgrade to Free"
											: tier.title === "Starter" &&
											  userInfo &&
											  userInfo.subscriptionType ===
													"Super"
											? "Downgrade to Starter"
											: tier.buttonText}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
			{/* <Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Link
					to="/"
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						color: "#437ffc",
						textDecoration: "underline",
						fontStyle: "italic",
					}}
				>
					Go back to Home
				</Link>
			</Box> */}
		</Box>
	);
};

export default Pricing;
