import { getCreateUserService } from "../services/apiHelpers";
import { PreviewLinkResponse, WeaviateResult } from "../openapi";

const usePreview = () => {

  const getPreviewPromise = (link: string): Promise<PreviewLinkResponse> => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const service = getCreateUserService();
        const response = await service.getPreviewLinkData(link);
        if (response.status === 200 || response.status === 201) {
          // console.log('response.data', response.data)
          resolve(response.data)
        }
      } catch (error: any) {
        reject(error)
      }
    })
  }

  const getAllPreviewPromises = async (results: WeaviateResult[]) => {
    if (!results || results?.length === 0) return;
    try {
      // const linkArr: string[] = []
      const promises: any[] = []; // Initialize an empty array to store promises
      // results.forEach(async (element: WeaviateResult) => {
      //   const { link } = element;
      //   // if (!linkArr.includes(link)) {
      //   // linkArr.push(link);
      //   promises.push(getPreviewPromise(link)); // Push the promise into the array
      //   // }
      // });
      // return await Promise.allSettled(promises); // Settle all promises
      await Promise.all( // Wait for all promises to resolve
        results.map(async (element: WeaviateResult) => {
          const { link } = element;
          promises.push(getPreviewPromise(link)); // Push the promise into the array
        })
      );
      return await Promise.allSettled(promises); // Settle all promises

    } catch (error: any) {
      return []
    }
  };

  const getPreviewProcessResult = async (result: WeaviateResult[]) => {
    if (result && result?.length > 0) {
      const resultWithOutPreview: WeaviateResult[] = result.filter((element: WeaviateResult) => !element.favicon || !element.description || !element.title);
      if (resultWithOutPreview.length === 0) return result;
      const previewPromise = await getAllPreviewPromises(result);
      if (previewPromise && previewPromise?.length > 0) {
        let resultWithPreview: any = []

        await Promise.all(
          previewPromise.map(async (element: PromiseSettledResult<PreviewLinkResponse>, index: number) => {
            if (element.status === 'fulfilled') {
              // console.log('previewPromise fulfilled', element.value)
              return resultWithPreview.push({
                ...result[index],
                imageUrl: element.value?.imageUrl === 'https://abs.twimg.com/errors/logo46x38.png' ? window.location.href + 'Place-Holder.png' : element.value?.imageUrl ?? window.location.href + 'Place-Holder.png',
                title: element.value?.title ? element.value?.title : result[index].title,
                favicon: element.value?.favicon,
                description: element.value?.description,
                tags: element.value?.tags
              });
            }
            else {
              // console.log('No image found for', element.status, result[index].link);
              return resultWithPreview.push({
                ...result[index],
                imageUrl: window.location.href + 'Place-Holder.png'
                // imageUrl: 'https://www.app.shome-ai.com/Place-Holder.png'
              })
            }
          })
        );
        return resultWithPreview;
      }
      return [];
    }
    return []
  };


  return {
    getAllPreviewPromises,
    getPreviewProcessResult,
    getPreviewPromise
  };
};

export default usePreview;