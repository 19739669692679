import { Box } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { ResetNewPasswordFormProps } from "../../interfaces/IResetPasswordStep2Form";
import { getFromLocalStorage } from "../../utils";
import { RESET_NEW_PASS_EMAIL } from "../../utils/constants";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import FormTitle from "./FormTitle";

const ResetNewPasswordForm = () => {
	const navigate = useNavigate();
	const { showSnackbar } = useAlert();
	const { loading, error, setError, resetNewPassword } = useAuth();

	const resetPasswordInitialValue: ResetNewPasswordFormProps = {
		email: "",
		password: "",
		confirmPassword: "",
		oldPassword: "",
	};

	const [initialValues, setInitialValues] = useState(
		resetPasswordInitialValue
	);

	const validationSchema = yup.object().shape({
		email: yup.string().email("Invalid email").required().label("Email"),
		password: yup
			.string()
			.min(8, "Password must be at least 8 characters")
			.required()
			.label("Password"),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password")], "Passwords must match")
			.required()
			.label("Confirm password"),
	});

	const onSubmit = async (
		values: ResetNewPasswordFormProps,
		actions: FormikHelpers<ResetNewPasswordFormProps>
	) => {
		try {
			actions.setSubmitting(true);
			setInitialValues(values);
			const response = await resetNewPassword(values);
			if (response) {
				navigate("/");
			}
		} catch (error: any) {
			showSnackbar(error?.message, error);
			actions.setSubmitting(false);
		}
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const oldPassword = queryParams.get("password") ?? "";

		let email =
			queryParams.get("email") ??
			getFromLocalStorage(RESET_NEW_PASS_EMAIL);
		if (email) {
			setInitialValues({
				...resetPasswordInitialValue,
				email: email,
				oldPassword,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<FormWrapper>
				{!loading && error && (
					<FormAlert error={error} setError={setError} />
				)}
				<FormTitle title={"Reset Password"} />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{() => {
						return (
							<Form>
								<FormField
									name="email"
									label="Email"
									placeholder="Email"
								/>
								<FormField
									name="password"
									label="Password"
									placeholder="Password"
									type="password"
								/>
								<FormField
									name="confirmPassword"
									label="Confirm Password"
									placeholder="Confirm password"
									type="password"
								/>
								<FormButton
									label={"Submit"}
									loading={loading}
								/>
							</Form>
						);
					}}
				</Formik>
			</FormWrapper>
		</Box>
	);
};

export default ResetNewPasswordForm;
